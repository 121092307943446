<template>
  <div class="technicalSupport">
    <div class="title" style="margin-top: 20px">什么是新药</div>
    <p class="content" style="margin-top: 0px">
      新药系指我国未生产过的药品，包括国内外均未生产过的创制药品，以及国外已有生产或仅有资料报道的仿制药品以及由一般药品配制的新组方药品。已生产过的药品，凡增加新的适应证、改变给药途径和改变剂型的亦属新药管理范围。新药在动物实验之后和获得“新药证书”之前，必须进行临床试验研究。
    </p>
    <div class="title" style="margin-top: 20px">新药研发过程</div>
    <img src="@/images/clinical/qa.png" class="post" />
    <div class="title" style="margin-top: 20px">什么是新药临床试验</div>
    <p class="content" style="margin-top: 0px">
      临床试验是一种前瞻性试验研究，指在人为条件控制下，以特定人群为受试对象(患者或健康志愿者)，以发现和证实干预措施(药品、特殊检查、特殊治疗手段)对特定疾病的防治、诊断的有效性（包括药品的作用、吸收、分布、代谢、排泄）和安全性（不良反应）。
    </p>
    <p class="content" style="margin-top: 0px">
      狭义的临床试验指药物，尤其是新药在人体进行的I期到IV期新药临床研究，目的是获得新药在人体的药代动力学参数及评价新药临床应用的疗效、适应证和安全性。
    </p>
    <div class="title" style="margin-top: 20px">
      新药进行临床试验必须经国家批准
    </div>
    <p class="content" style="margin-top: 0px">
      新药在上市前都必须进行临床试验，而且必须经过国家药监局（也就是现在的国家食品药品监督管理局，SFDA）的批准，取得新药的临床试验批准文件（文号）之后，才有可能实施。取得批准文号是实施临床试验的法律前提。
    </p>
    <p class="content">
      因为涉及人体试验，为了保障参加试验的患者或者健康志愿者的人身安全与健康，批准进行临床试验的过程是非常严格的，研究新药的制药企业或者研究机构要进行大量的实验室研究，取得动物的疗效与安全性试验数据，以及其它的药学数据，所有这些研究工作，都有详细的技术指导原则来进行规范而得到国家药监局的批准，只是进行临床试验之前的第一步，要想实施临床试验，还必须通过独立伦理委员会的审核。
    </p>
    <p class="content">
      每个能够进行临床试验的医院，都有一个由医生与其它职业的人员（外单位）组成的独立伦理委员会，负责对在本医院进行的临床试验进行伦理审核，这是保障参加试验人员安全、权益的另一个有效措施。伦理审核将对参加试验的受益（好处）与风险进行综合权衡，只有当参加试验的受益大于风险时，这个临床试验才会通过独立伦理委员会的审核，然后医生们才会按照要求进行临床试验。
    </p>
    <div class="title" style="margin-top: 20px">
      临床试验的意义
    </div>
    <p class="content">
      临床试验为比较两种或更多种诊断或治疗措施提供基础；为诊断或治疗结果的正确性提供最大程度的可信性；为观察结果的差异提出有参考意义的结论。
    </p>
    <div class="height40"></div>
  </div>
</template>
<script>
export default {
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="scss">
.technicalSupport {
  font-size: 24px;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  color: #000000;
  font-weight: normal;
  .post {
    width: 100%;
    height: auto;
  }
  .title {
    font-size: 32px;
    font-weight: bold;
  }
  .content {
    text-indent: 0em;
    font-size: 30px;
    line-height: 55px;
    color: #4f4f4f;
  }
}
</style>
